import { Injectable } from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginOverlayService {
  private overlayOpenedSubject = new BehaviorSubject<boolean>(false);
  overlayOpened$ = this.overlayOpenedSubject.asObservable();

  openedPane: 'login' | 'signup' | 'request-password-reset' = 'login';

  constructor() {
    fromEvent(document, 'click')
      .pipe(
        // Filtrer les clics pour ne conserver que ceux en dehors de la popup
        filter((event: MouseEvent) => {
          const overlayElement = document.getElementById('votre-popup-id'); // Remplacez par l'ID de votre popup
          return overlayElement && !overlayElement.contains(event.target as Node);
        }),
        // Arrête l'écoute après le premier clic en dehors de la popup
        takeUntil(this.overlayOpened$)
      )
      .subscribe(() => {
        // Appel de la méthode pour gérer le clic en dehors de la popup
      });

  }
  openLogin() {
    this.overlayOpenedSubject.next(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  closeLogin() {
    this.overlayOpenedSubject.next(false);

  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppConfig } from 'tekkeys-common';

const TEKKEYS_DEV_CONFIG: AppConfig = {
  name: 'Tekkeys front office development configuration',
  apiEndpoint: 'https://api.imtech.dev.imtech-solutions.com/api/v1'
  //apiEndpoint: 'http://localhost:80/api/v1'
};

export const environment = {
  production: false,
  appConfig: TEKKEYS_DEV_CONFIG,
  apiKeyStripe:
    'pk_test_51HFgSgLDNcQS7uB4qvOWfsWLlTMzoLkh6399Nwt0rl4Ao6FYd4FEG6lCppr6NCcHjrVkZCaHGkvZmJvdWWA9kVoo00bccHlyel',
  apiWebSocket: 'localhost:8080/api/v1/ws/notifications',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `z
 * one.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {footerInfoDto} from '../../DTO/footer-info.dto';
import {APP_CONFIG, AppConfig} from '../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class FooterInfoService {
  private url: string;

  constructor(private http: HttpClient,
  ) {
    this.url = 'https://api.imtech.dev.imtech-solutions.com/api/v1/contactInformation';
  }

  public getAllContactInformation(): Observable<any[]> {
    return this.http.get<footerInfoDto[]>(this.url);

  }

}

import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { AuthTokenDTO } from '../../DTO/auth-token.dto';
import { ClientDTO } from '../../DTO/client.dto';
import { ClientUpdateRequestDTO } from '../../DTO/client-update-request.dto';
import { ClientAddressDTO } from '../../DTO/client-address.dto';
import { SubscriptionDto } from '../../DTO/subscription.dto';
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private url: string;
 private urls:string;


  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/client`;
    this.urls = `${this.appConfig.apiEndpoint}`;

  }

  public me(): Observable<ClientDTO> {
    const url = `${this.url}/me`;
    return this.http.get<ClientDTO>(url);
  }

  public updateMe(
    clientUpdateRequest: ClientUpdateRequestDTO
  ): Observable<ClientDTO> {
    const url = `${this.url}/me`;
    return this.http.put<ClientDTO>(url, clientUpdateRequest);
  }
  public refreshToken(): Observable<AuthTokenDTO> {
    const url = `${this.url}/refresh-token`;
    const token = localStorage.getItem('authToken'); // Get the token from localStorage
    console.log('Current token before refresh:', token);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}` // Add the token to the Authorization header
    });

    return this.http.get<AuthTokenDTO>(url, { headers }).pipe(
      tap((response: AuthTokenDTO) => {
        console.log('New token received:', response.token);
        localStorage.setItem('authToken', response.token);
        console.log('Token after setItem:', localStorage.getItem('authToken'));
      })
    );
  }



  public login(mail: string, password: string): Observable<AuthTokenDTO> {
    const url = `${this.url}/login`;
    return this.http.put<AuthTokenDTO>(url, { mail, password });
  }

  public signIn(token: string): Observable<AuthTokenDTO> {
    const url = `${this.url}/sign-in?token=${token}`;
    return this.http.put<AuthTokenDTO>(url, {});
  }

  public signup(
    lastname: string,
    name: string,
    mail: string,
    password: string,
    conditionsValidated: boolean
  ): Observable<ClientDTO> {
    const url = `${this.url}/register`;
    return this.http.post<ClientDTO>(url, {
      lastname,
      name,
      mail,
      password,
      conditionsValidated,
    });
  }

  public verifyEmail(mail: string): Observable<void> {
    const url = `${this.url}/verify-email`;
    return this.http.post<void>(url, { mail });
  }

  public confirm(token: string): Observable<ClientDTO> {
    const url = `${this.url}/confirm`;
    const params = new HttpParams().append('token', token);
    return this.http.get<ClientDTO>(url, { params });
  }

  public confirmCollaborator(
    token: string,
    password: string
  ): Observable<ClientDTO> {
    const url = `${this.url}/confirm`;
    const payload = {
      token,
      password,
      conditionsValidated: true,
    };
    return this.http.post<ClientDTO>(url, payload);
  }

  public resendConfirmationMail(mail: string) {
    const url = `${this.url}/resend`;
    return this.http.post(url, { mail });
  }

  public requestPasswordReset(mail: string): Observable<void> {
    const url = `${this.url}/request-password-reset`;
    return this.http.put<void>(url, { mail });
  }

  public passwordReset(token: string, password: string): Observable<ClientDTO> {
    const url = `${this.url}/password-reset`;
    return this.http.put<ClientDTO>(url, { token, password });
  }

  public verifyPasswordResetToken(token: string): Observable<void> {
    const url = `${this.url}/verify-reset-password-token`;
    return this.http.post<void>(url, token);
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<void> {
    const url = `${this.url}/change-password`;
    return this.http.patch<void>(url, { oldPassword, newPassword });
  }

  public anonymousSubscriptionToNewsletter(mail: string): Observable<void> {
    const url = `${this.url}/subscription/anonymous`;
    return this.http.post<void>(url, mail);
  }

  public anonymousUnsubscriptionToNewsletter(mail: string): Observable<void> {
    const url = `${this.url}/subscription/anonymous`;
    return this.http.delete<void>(`${url}/${mail}`);
  }

  public getCollaborators(): Observable<ClientDTO[]> {
    const url = `${this.url}/me/collaborators`;
    return this.http.get<ClientDTO[]>(url);
  }

  public getMyCollaborators(): Observable<ClientDTO[]> {
    const url = `${this.url}/me/collaborators`;
    return this.http.get<ClientDTO[]>(url);
  }
  public getCiviliter(){
    const url = `${this.urls}/collaborator-titles`;
    return this.http.get<any>(url);

  }

  public updateCollaborator(collaborator: any): Observable<any> {
    const url = `${this.url}/me/collaborators`;

    return this.http.put<any>(url, collaborator).pipe(
      tap(response => console.log('Full response:', response)), // Log the entire response
      map(response => response.data) // Retourne seulement `data` du résultat
    );
  }

  public deleteCollaborator(id: any): Observable<any> {
    const url = `${this.url}/me/collaborators/${id}`;
    return this.http.put<any>(url, null);
  }

  public leaveCollaborator(): Observable<any> {
    const url = `${this.url}/collaborators/leave/company`;
    return this.http.put<any>(url, null);
  }

  public addCollaborator(collaborator: ClientDTO[]): Observable<any> {
    const url = `${this.url}/invite`;
    return this.http.post<any>(url, collaborator);
  }

  public assignMainContact(clientId: number): Observable<any> {
    const url = `${this.url}/${clientId}/assign-main-contact`;
    return this.http.post<any>(url, null);
  }

  public desactivateCollaboratorStatus(collaboratorId: any): Observable<any> {
    const url = `${this.url}/${collaboratorId}/deactivate`;
    return this.http.patch<any>(url, collaboratorId);
  }

  public activateCollaboratorStatus(collaboratorId: number): Observable<any> {
    const url = `${this.url}/${collaboratorId}/activate`;
    return this.http.patch<any>(url, collaboratorId);
  }

  public resendCollaboratorMail(collaboratorId: number): Observable<any> {
    const url = `${this.url}/${collaboratorId}/re-invite`;
    return this.http.post<any>(url, collaboratorId);
  }

  public getCollaboratorFunctions(): Observable<any> {
    const url = `${this.url}/function`;
    return this.http.get<any>(url);
  }

  public addClientAddress(
    clientAddress: ClientAddressDTO,
    idUser: any
  ): Observable<any> {
    const url = `${this.url}/addressbook`;
    return this.http.post<any>(url, clientAddress);
  }

  public updateClientAddress(
    clientAddress: ClientAddressDTO,
    idUser: any
  ): Observable<any> {
    const url = `${this.url}/${idUser}/addressbook`;
    return this.http.put<any>(url, clientAddress);
  }

  public getClientAddresses(): Observable<ClientAddressDTO[]> {
    const url = `${this.url}/addressbook`;
    return this.http.get<ClientAddressDTO[]>(url);
  }

  public deleteClientAddress(idUser: any, idAddress: any): Observable<any> {
    const url = `${this.url}/${idUser}/addressbook/${idAddress}`;
    return this.http.delete<any>(url);
  }

  public updateClient(
    client: ClientDTO,
    token?: string
  ): Observable<ClientDTO> {
    const url = `${this.url}${token ? '?token=' + token : ''}`;
    return this.http.put<any>(url, client);
  }

  public getClient(id: number): Observable<ClientDTO> {
    const url = `${this.url}/${id}`;
    return this.http.get<ClientDTO>(url);
  }

  public resetAttempts(mail: string): Observable<void> {
    const url = `${this.url}/reset-attempts`;
    return this.http.patch<void>(url, { mail });
  }

  public getSubscription(): Observable<SubscriptionDto> {
    const url = `${this.url}/subscription`;
    return this.http.get<SubscriptionDto>(url);
  }
  public updateSubscription(
    subscription: SubscriptionDto
  ): Observable<SubscriptionDto> {
    const url = `${this.url}/subscription`;
    return this.http.put<SubscriptionDto>(url, subscription);
  }
  public getClientsByFunction(): Observable<ClientDTO[]> {
    const url = `${this.url}/ClientFiltered`;
    return this.http.get<ClientDTO[]>(url);
  }
}

<div class="app-connection-dialog-container"  >

<form *ngIf="step === 'step_1'" [formGroup]="signupForm.get('step_1')" (ngSubmit)="nextStep($event)"
      (keydown.enter)="nextStep($event)" >

  <app-connection-dialog fxLayout="column">
    <div class="container">
        <span *ngIf="!error" class="message">Bienvenue
          <button class="closeButton" (click)="close()">
            <img src="assets/icons/close-gray.svg" alt="close" />
          </button>
        </span>
    </div>
    <span *ngIf="error" class="error-message">
      {{ errorMessage }}
    </span>

    <div fxLayout fxLayoutGap="16px">
      <div fxFlex="50%">
        <app-text-input label="Nom" formControlName="lastname"
                        (keypress)="onKeyPress($event)"
        ></app-text-input>
        <span *ngIf="
            lastnameControl.touched && lastnameControl.hasError('required')
          " class="validation-error-message">
          Champ obligatoire
        </span>
        <span *ngIf="lastnameControl.touched && lastnameControl.hasError('pattern')" class="validation-error-message">
          Only letters
        </span>
      </div>

      <div fxFlex="50%">
        <app-text-input label="Prénom" formControlName="name"
                        (keypress)="onKeyPress($event)"
        ></app-text-input>
        <span *ngIf="nameControl.touched && nameControl.hasError('required')" class="validation-error-message">
          Champ obligatoire
        </span>
        <span *ngIf="nameControl.touched && nameControl.hasError('pattern')" class="validation-error-message">
          Only letters
        </span>
      </div>
    </div>

    <app-email-input formControlName="mail" appVerifyEmail></app-email-input>
    <span *ngIf="mailControl.touched && mailControl.hasError('required')" class="validation-error-message">
      Ce champ est obligatoire
    </span>
    <span *ngIf="mailControl.touched && mailControl.hasError('pattern')" class="validation-error-message">
      Veuillez entrer une adresse mail professionnelle valide
    </span>
    <span *ngIf="mailControl.touched && mailControl.hasError('emailAlreadyUsed')" class="validation-error-message">
      Cette adresse mail est déjà utilisée
    </span>

    <a class="link" fxFlexAlign="end" (click)="goToLogin.emit()">
      Se connecter
    </a>
    <button class="app-stroked-button" type="submit" [disabled]="signupForm.get('step_1').invalid || isLoading" >
      S'INSCRIRE
    </button>
  </app-connection-dialog>
</form>

<form *ngIf="step === 'step_2'" [formGroup]="signupForm.get('step_2')" (ngSubmit)="onSignup()" (keydown.enter)="onEnter($event)">
  <app-connection-dialog fxLayout="column">

    <span *ngIf="!success && passwordControl.invalid" class="message">
      Entrer un mot de passe pour continuer

    </span>
    <span *ngIf="error" class="error-message">{{ errorMessage }}</span>
    <app-password-input formControlName="password" [visibilityToggle]="true"></app-password-input>
    <span class="hint" [ngClass]="{
        'hint-initial': !passwordControl.dirty,
        'hint-success': passwordControl.dirty && passwordControl.valid,
        'hint-error': passwordControl.dirty && passwordControl.invalid
      }">
      8 caractères minimum, 1 majuscule, 1 chiffre, 1 caractère spécial
    </span>

    <div class="conditions-validated">
      <input formControlName="conditionsValidated" id="conditions-validated" class="app-checkbox-input"
        type="checkbox" />
      <label for="conditions-validated" class="app-checkbox-input-label">
        <span>
          J’accepte
          <a class="link" routerLink="/about/general-condition" target="_blank">les conditions générales</a>et
          <a class="link" routerLink="/about/cookies" target="_blank">les termes de confidentialité</a>
        </span>
      </label>
    </div>

    <button class="app-flat-button" type="submit" [disabled]="signupForm.get('step_2').invalid || !passwordControl.value && !signupForm.get('conditionsValidated').value"
    >
      CONTINUER
    </button>
  </app-connection-dialog>
</form>
</div>

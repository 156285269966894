import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { ClientService } from 'tekkeys-common';
import {LoginOverlayService} from 'shared/services/overlay/login-overlay.service';
//import {MatDialog} from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ResendConfirmationMailComponent} from 'shared/components/resend-confirmation-mail/resend-confirmation-mail.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  step: 'step_1' | 'step_2' = 'step_1';
  dialogRef: MatDialogRef<any>;

  error = false;
  errorMessage = 'Error';
  isLoading = false;
  success = false;
  isPopupOpen = true;
  signupForm: UntypedFormGroup;
  get nameControl(): AbstractControl {
    return this.signupForm.get(['step_1', 'name']);
  }
  get lastnameControl(): AbstractControl {
    return this.signupForm.get(['step_1', 'lastname']);
  }
  get mailControl(): AbstractControl {
    return this.signupForm.get(['step_1', 'mail']);
  }
  get passwordControl(): AbstractControl {
    return this.signupForm.get(['step_2', 'password']);
  }

  @Output() goToLogin = new EventEmitter<void>();
  @Output() gotToCode = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog,
    private loginOverlayService: LoginOverlayService,

  ) {}

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      step_1: this.fb.group({
        name: ['', Validators.compose([Validators.required])],
        lastname: ['', Validators.compose([Validators.required])],
        mail: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            ),
          ]),
        ],
      }),
      step_2: this.fb.group({
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s]).{8,}$/
            ),
          ]),
        ],
        conditionsValidated: [false, Validators.requiredTrue],
      }),
    });
  }
  onKeyPress(event: KeyboardEvent): boolean {
    const regex = new RegExp('^[a-zA-Z]*$'); // Allow only letters
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!regex.test(inputChar)) {
      event.preventDefault(); // Prevents input if it's not valid
      return false;
    }
    return true;
  }

  nextStep(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault(); // Prevent form submission
    }
    if ( this.signupForm.get('step_1').invalid ) {
      return;
    }
    else{
    const payload = this.signupForm.value;
    this.clientService.verifyEmail(payload.step_1.mail.toLowerCase()).subscribe(
      () => {
        this.error = false;
        this.step = 'step_2';
      },
      (error: HttpErrorResponse) => {
        this.step = 'step_1';
        this.isLoading = false;
        this.success = false;
        this.error = true;
        switch (error.status) {
          case 400:
            this.errorMessage = 'Client data is not valid';
            break;
          case 403:
            this.errorMessage = 'Mail domain is blacklisted';
            break;
          case 409:
            this.errorMessage = 'Cette adresse mail est déjà utilisée';
            break;
          default:
            this.errorMessage = 'Error';
            break;
        }
        setTimeout(() => {
          this.closeOverlay.emit();
        }, 5000);
      });}
  }
  close() {
    this.isPopupOpen = !this.isPopupOpen;

    this.loginOverlayService.closeLogin();
  }
  onEnter(event: KeyboardEvent) {
    const passwordControl = this.signupForm.get('step_2.password');
    const conditionsValidated = this.signupForm.get('step_2.conditionsValidated')?.value;

    if (passwordControl?.value && conditionsValidated) {
      // Vérifiez si le mot de passe est valide
      if (passwordControl.valid) {
        event.preventDefault();
        this.onSignup();
      } else {
        // Affichez un message d'erreur ou autre action si le mot de passe est invalide
        console.warn('Le format du mot de passe est invalide.');
      }
    }
  }

  onSignup() {
    const payload = this.signupForm.value;
    this.isLoading = true;
    this.clientService
        .signup(
          payload.step_1.lastname,
          payload.step_1.name,
          payload.step_1.mail.toLowerCase(),
          payload.step_2.password,
          payload.step_2.conditionsValidated
        )
        .subscribe(
          () => {
            this.success = true;
            this.error = false;
            setTimeout(() => {
              this.closeOverlay.emit();
            }, 500);
            // Passez les données ici
            this.dialogRef = this.dialog.open(ResendConfirmationMailComponent, {
              data: { email: payload.step_1.mail.toLowerCase() }
            });
          },
          (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.success = false;
            this.error = true;
            switch (error.status) {
              case 400:
                this.errorMessage = 'Client data is not valid';
                break;
              case 403:
                this.errorMessage = 'Mail domain is blacklisted';
                break;
              case 409:
                this.errorMessage = 'Cette adresse mail est déjà utilisée';
                break;
              default:
                // this.errorMessage = 'Error';
                break;
            }
            setTimeout(() => {
              this.closeOverlay.emit();
            }, 5000);
          }
        );
    }
}

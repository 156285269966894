<div class="dialog" xmlns="http://www.w3.org/1999/html">
  <div class="dialog-titlebar">
    &nbsp;<h3>Ajouter / rejoindre une entreprise</h3>
    <button  style="background-color: var(--red-700); border: transparent;cursor: pointer"
            [routerLink]="currentPage === 'confirm' ? '/' : '/profile/company'"
          >
      <img           (click)="close()"
                     src="assets/icons/close.svg" alt="close" />
    </button>
  </div>
    <div class="dialog-content">


  <form [formGroup]="companyForm">
    <div fxLayout="column" *ngIf="step === 1">
        <span class="success-message" [ngClass]="{ 'error-message': error }">
          {{ msg }}
        </span>

      <mat-form-field floatLabel="never" appearance="outline">
        <img style="margin-left: 10px;" matPrefix src="assets/icons/business.svg" alt="Icone busniss" />
        <input
          style="padding-left: 11px"
          matInput
          class="company-input"
          formControlName="name"
          type="text"
          placeholder="Nom de votre entreprise"
          [matAutocomplete]="searchAutocomplete"
        />
      </mat-form-field>
      <mat-autocomplete #searchAutocomplete="matAutocomplete">
        <mat-option
          *ngFor="let company of companyList"
          [value]="company.name"
          (onSelectionChange)="selectCompany(company)"

        >
          {{ company.name }}
        </mat-option>
      </mat-autocomplete>


      <div style="margin-top: 24px;" fxLayout fxLayoutAlign="space-between">

        <button
          class="app-stroked-button"
          fxFlex="48"
          [routerLink]="currentPage === 'confirm' ? '/' : '/profile/company'"
          (click)="overlayService.closeDilog()"
        >
          PLUS TARD
        </button>
        <button class="app-flat-button" fxFlex="48" (click)="onContinue()">
          {{ companyList?.length === 0 ? 'AJOUTER' : 'CONTINUER' }}
        </button>
      </div>
    </div>

    <div class="add-new-company" >

      <mat-horizontal-stepper  linear #stepper>
        <div class="add-new-company"  *ngIf="step === 2">
          <b style="font-size: 16px; color: #404040 ; ">Informations sur la société</b>
        <mat-step [stepControl]="companyForm" [completed]="companyForm.get('company.tva').valid && conditionToShowStep()"

      >
        <div fxLayout="column" formGroupName="billing_address">
          <div fxLayout fxLayoutGap="16px">
            <app-text-input
              style="width: 100%"
              formControlName="country"
              label="Pays"
              [matAutocomplete]="country"
              [postIcon]="'assets/icons/arrow_drop_down.svg'"
            ></app-text-input>
          </div>
          <mat-autocomplete #country="matAutocomplete" (optionSelected)="onCountrySelected($event)">
            <mat-option
              *ngFor="let country of countries$ | async"
              [value]="country.name">
              {{ country.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div formGroupName="company">
          <div  fxLayout fxLayoutGap="16px">
            <app-text-input
              style="width: 100%"
              formControlName="legalSituations"
              label="situation juridique"
              [matAutocomplete]="situationjuridique"
              [postIcon]="'assets/icons/arrow_drop_down.svg'"
            ></app-text-input>
          </div>
          <mat-autocomplete #situationjuridique="matAutocomplete" [displayWith]="displayFn" >
              <mat-option
                *ngFor="let type of companyOptions "
                [value]="type">
                {{ type.description }} ({{type.code}})
              </mat-option>

            </mat-autocomplete>
            <app-text-input
              *ngIf="selectedCountry==='France'"
              class=""
              formControlName="siret"
              label="SIRET"
            ></app-text-input>
            <mat-error *ngIf="companyForm.get('company.siren').touched">
            </mat-error>
            <app-text-input
              *ngIf="selectedCountry==='France'"
              style="margin-top:-13px"
              formControlName="siren"
              label="SIREN"
            ></app-text-input>
            <mat-error *ngIf="companyForm.get('company.siren').hasError('required') && companyForm.get('company.siren').touched">
            </mat-error>
            <app-text-input
              style="margin-top: -9px"

              label="TVA" placeholder="TVA-NIF" formControlName="tva"></app-text-input>
            <mat-error *ngIf="companyForm.get('company.tva').hasError('pattern') ">
              Les lettres en Majuscules
            </mat-error>
          <app-text-input
            *ngIf="selectedCountry==='France'"
            class=""
            formControlName="naf"
            label="NAF"
          ></app-text-input>
          <app-phone-input
            formControlName="phone"
            label="Téléphone"
          ></app-phone-input>
          <mat-error *ngIf="companyForm.get('company.phone').hasError('pattern') && companyForm.get('company.phone').touched">
          </mat-error>
        </div>

          <div style="display:flex; justify-content: space-between;margin-top: 1em">
            <button
              style="width: 48%; height: 2.5em "
              class="app-stroked-button"
              type="button"
              (click)="goToTop(); step = 1"
            >

              PRECEDENT
            </button>
            <button  style="width: 48%; height: 2.5em"
                     class="app-flat-button"
                     type="button"
                     (click)="getSituationCode()"
                     [disabled]="!companyForm.get('company.tva').value"
                     mat-button matStepperNext>SUIVANT</button>
          </div>
        </mat-step>

        <mat-step formGroupName="billing_address" [completed]="companyForm.get('billing_address.city').valid && conditionToShowStep()" >
          <app-text-input formControlName="addressLine1" label="Adresse"></app-text-input>

          <app-text-input  formControlName="addressLine2" label="Adresse complémentaire"></app-text-input>
          <div fxLayout fxLayoutGap="16px">
            <app-text-input class="" formControlName="city" label="Ville" required></app-text-input>
            <app-text-input class="" formControlName="zipCode" label="Code postal" required></app-text-input>
          </div>
          <div style="display:flex; justify-content: space-between; margin-top: 1em">
            <button   style="width: 48%; height: 2.5em "
                      class="app-stroked-button" mat-button
                      matStepperPrevious>PRECEDENT
            </button>
            <button
                     style="width: 48%; height: 2.5em"
                     class="app-flat-button"
                     type="button"
                     [disabled]="!companyForm.get('billing_address').valid"
                     mat-button matStepperNext>SUIVANT</button>
          </div>
        </mat-step>
          <mat-step formGroupName="company" >
            <div>
              <span>Document d'entreprise :</span>
              <div >
                <div >
                  <div class="etape">
                    <span class="etape-item">                  Pour le document 'formulaire d’ouverture de compte', suivez les étapes suivantes :</span>
                    <span class="etape-item">
                      <div class="columnn">
                      <a>        Étape 1 : Téléchargez le document.</a>
                      <div>
                      ⬇ <a style="color: #B71C1C; text-decoration: underline;cursor: pointer;" (click)="downloadFormulairePdf('fr')">En Français</a>
                        ou
                      ⬇ <a style="color: #B71C1C; text-decoration: underline;cursor: pointer;" (click)="downloadFormulairePdf('eng')">En Anglais </a>
                    </div>
                    </div></span>
                    <span class="etape-item">Étape 2 : Remplissez et signez un seul formulaire.</span>
                    <span class="etape-item">Étape 3 : Importez le document signé dans le champ 'Formulaire'</span>
                  </div>
                  <div style="margin-top: 1em">
                    <div class="divInput" [ngClass]="{'error': companyForm.get('company.fileUpload1').hasError('required') && companyForm.get('company.fileUpload2').touched}">
                      <span class="placeHolder" *ngIf="!selectName">KBIS</span>
                      <span    for="fileUpload1" class="fileName"  >{{ selectName}}</span><br>
                      <input type="file" formControlName="fileUpload1" id="fileUpload1" #testy style="display: none" (change)="onFileChange($event,1)" formControlName="fileUpload1" required>
                      <input type="text" formControlName="fileUpload1" style="display: none">
                      <button mat-button (click)="testy.click()" class="parcourirButton"> PARCOURIR </button>
                    </div>
                    <div class="divInput" [ngClass]="{'error': companyForm.get('company.fileUpload2').hasError('required') && companyForm.get('company.fileUpload2').touched}">
                      <span class="placeHolder" *ngIf="!selectName1" >RIB</span>
                      <span    for="fileUpload2" class="fileName"  >{{ selectName1}}</span><br>
                      <input type="file" formControlName="fileUpload2" id="fileUpload2" #test style="display: none" (change)="onFileChange($event,2)" formControlName="fileUpload2" required>
                      <input type="text" formControlName="fileUpload2" style="display: none">
                    <button mat-button (click)="test.click()" class="parcourirButton"> PARCOURIR </button>
                    </div>
                    <mat-error *ngIf="companyForm.get('company.fileUpload2').hasError('required') && companyForm.get('company.fileUpload2').touched">
                    </mat-error>
                    <div class="divInput" style="margin-top: -12px" [ngClass]="{'error': companyForm.get('company.fileUpload').hasError('required') && companyForm.get('company.fileUpload2').touched}">
                      <span class="placeHolder" *ngIf="!selectName2" >FORMULAIRE</span>
                      <span    for="fileUpload" class="fileName"  >{{selectName2}}</span><br>
                      <input type="file" formControlName="fileUpload" id="fileUpload" #test1 style="display: none;" (change)="onFileChange($event,3)" formControlName="fileUpload" required>
                    <input type="text" formControlName="fileUpload" style="display: none;">
                    <button mat-button (click)="test1.click()" class="parcourirButton"> PARCOURIR </button>
                  </div>
                  <mat-error style="width: 400em" *ngIf="companyForm.get('company.fileUpload').hasError('required') && companyForm.get('company.fileUpload1').touched">
                    Vous devez ajouter tous les documents.
                  </mat-error>
               </div>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between ;margin-top: 1em">
                <button   style="width: 48%; height: 2.5em "
                          class="app-stroked-button" mat-button
                          matStepperPrevious>PRECEDENT
                </button>
              <button
                style="width: 48%; height: 2.5em"
                class="app-flat-button"
                type="button"
                [disabled]="!companyForm.get('company.fileUpload').value && !companyForm.get('company.fileUpload2').value"
                (click)="createNewCompany()"
              >
                     AJOUTER
                <mat-spinner  *ngIf="loading===true" diameter="20">
                </mat-spinner>
              </button>
            </div>
          </div>
        </mat-step>
      </div>
      </mat-horizontal-stepper>
    </div>
  </form>
  </div>
</div>
